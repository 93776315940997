// TopArtists.tsx
import { useEffect, useState } from 'react';
import ArtistsService from '../services/Artists';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { CTAButton } from './Utils';

const TopArtists = () => {
  const [topArtists, setTopArtists] = useState<any[]>([]);

  useEffect(() => {
    const fetchTopArtists = async () => {
      const artists = await ArtistsService.getTopArtists();
      setTopArtists(artists);
    };

    fetchTopArtists();
  }, []);

  if (!topArtists) {
    return null;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h4" mb={1}>
        Artist Buzz
      </Typography>
      <Box maxHeight="300px" sx={{ overflowY: 'scroll' }} mb={1}>
        {topArtists.map((artist) => (
          <div key={artist.id}>
            <Typography variant="h5">
              <RouterLink to={`/artist/${artist.id}`}>{artist.name}</RouterLink>
            </Typography>
          </div>
        ))}
      </Box>
      <CTAButton href="/artists" text="View All Artists" fullWidth mb={2} />
    </Box>
  );
};

export default TopArtists;
